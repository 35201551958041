// Initialize Bootstrap functionality
// Initialize tooltip component
$(function() {
    $('[data-toggle="tooltip"]').tooltip()
})
// Initialize popover component
$(function() {
    $('[data-toggle="popover"]').popover()
})


// ===== Honeypot ==== 
/* $('form').submit(function(){    
    if ($('input#nutzenText').val().length != 0) {
        return false;
    } 
}); */


// ScrollReveal
/*
window.sr = ScrollReveal({
    viewFactor: 0.1,
    reset: true
});
sr.reveal('.scrollreveal');
sr.reveal('.card');
sr.reveal('.table');
sr.reveal('.google-maps');
sr.reveal('footer');
*/

// lottie function
function loadLottie(anchor) {
    //var animations = ['kalender','dienstleistungen','apm3-recruiting','ueber-uns','recruiting'];
    var animation = bodymovin.loadAnimation({
        container: document.getElementById(anchor), // Required
        path: '/js/lottie/'+anchor+'.json', // Required
        renderer: 'svg', // Required
        loop: false, // Optional
        autoplay: true, // Optional
        rendererSettings: {
            progressiveLoad:true,
            viewBoxOnly: true,
            preserveAspectRatio:'xMidYMid meet'
        }
      });
}

// lottie destroy function
function destroyLottie() {
    var animations = ['kalender','dienstleistungen','apm3-recruiting','ueber-uns','recruiting','tagesanzeiger','fuehrung'];
    var i, len = animations.length;
    for(i = 0; i < len; i += 1) {
        var anim;
        var elem = document.getElementById(animations[i])
        lottie.destroy();
    }
}

$(document).ready(function(){
    var location = window.location.href;
    if(location.indexOf("sendsuccess") > -1) {
        $('#successModal').modal('show')
    }
});


// fullpage.js
$('#fullpage').fullpage({
    anchors: ['fpHeader', 'fpEvents', 'fpRecruiting', 'fpFuehrung', 'fpTagesanzeiger', 'fpDienstleistungen', 'fpUeberUns', 'fpFooter'],
    //navigationTooltips: ['Home', 'Veranstaltungen', 'Kontakt'],
    afterLoad: function(anchorLink, index){
        var loadedSection = $(this);
        if (anchorLink == 'fpDienstleistungen'){
            loadLottie('dienstleistungen');
        } else if (anchorLink == 'fpHeader'){
            loadLottie('apm3-recruiting');
        } else if (anchorLink == 'fpFuehrung'){
            loadLottie('fuehrung');
        } else if (anchorLink == 'fpTagesanzeiger'){
            loadLottie('tagesanzeiger');
        } else if (anchorLink == 'fpEvents'){
            loadLottie('kalender');
        } else if (anchorLink == 'fpUeberUns'){
            loadLottie('ueber-uns');
        } else if (anchorLink == 'fpRecruiting'){
            loadLottie('recruiting');
        }
    },
    onLeave: function(index, nextIndex, direction){
        var leavingSection = $(this);
        if(direction == 'up' || direction == 'down'){
            destroyLottie();
        } 
    },
    navigation: true,
    navigationPosition: 'right'
});


// lottie.js
/*
var animations = ['kalender','dienstleistungen','apm3-recruiting','ueber-uns'];
var i, len = animations.length;
for(i = 0; i < len; i += 1) {
    var anim;
    var elem = document.getElementById(animations[i])
    var animData = {
        container: elem,
        renderer: 'svg',
        loop: false,
        autoplay: true,
        rendererSettings: {
            progressiveLoad:true,
            preserveAspectRatio:'xMidYMid meet'
        },
        path: '/js/lottie/'+animations[i]+'.json'
    };
    anim = lottie.loadAnimation(animData);
    anim.setSubframe(false);
}*/



// Back to top Button
$(document).ready(function(){
    $('body').append('<div id="toTop" class="btn btn-sm btn-primary"><i class="fa fa-arrow-up"></i></div>');
    $(window).scroll(function () {
        if ($(this).scrollTop() != 0) {
            $('#toTop').fadeIn();
        } else {
            $('#toTop').fadeOut();
        }
    });
    $('#toTop').click(function(){
        $("html, body").animate({ scrollTop: 0 }, 600);
        return false;
    });
});


// MATH CAPTCHA
/* var a, b;

    function getRandom() {

        var numbers = new Array();

        for(var i=1;i<100;i++) {
            numbers.push(i);
        }

        a = numbers[Math.floor(Math.random() * numbers.length)];
        b = numbers[Math.floor(Math.random() * numbers.length)];

        document.getElementById("firstNumber").innerHTML = a;
        document.getElementById("secondNumber").innerHTML = b;

    }

    function check() {

        var result = document.getElementById("result").value;

        if(result == (parseInt(a) + parseInt(b))) {
            return true;
        } else {
            alert("Bitte prüfen Sie das Ergebnis der Rechnung!");
        }
    } */